@media only screen and (max-width: 780px) {
  .function {
    height: 100%;
    padding-bottom: 30px;
    .content {
      display: flex;
      flex-direction: column;
      .desc {
        margin: initial;
        padding-top: 26px;
      }
      .func-logo {
        width: 90%;
        padding: 20px 0;
        img {
          width: 320px;
        }
      }
    }
    .contact {
      height: 128px;
      flex-direction: column;
      justify-content: center;
      p {
        font-size: 18px;
        .email {
          display: inline-block;
          margin-top: 5px;
        }
      }
      .contactButton {
        width: 121px;
        margin-top: 13px;
        height: 36px;
        line-height: 36px;
        background: #1195F0;
        border-radius: 100px 100px 100px 100px;
        opacity: 1;
        border: none;
        cursor: pointer;
        font-size: 15px;
        color: #FFF;
        font-weight: 600;
        a {
          color: #FFF;
          text-decoration: none;
        }
      }
    }
  }
}