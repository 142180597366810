
.medium {
  background: #FFF;
  height: 506px;
  width: 100%;
  position: relative;
  .content {
    .title, .desc {
      color: #1D1F75;
    }
    .title {
      margin-bottom: 28px;
      font-size: 40px;
    }
    .desc {
      font-size: 20px;
      font-family: Segoe UI-Regular, Segoe UI;
    }
  }
  .con {
    width: 80%;
    margin: 0 auto;
    .swiper-wrapper {
      height: 180px;
      .warp {
        display: flex;
        width: 80%;
        justify-content: center;
        .item {
          width: 388px;
          height: 144px;
          background: #1195F0;
          border-radius: 15px;
          &:not(:first-child) {
            margin-left: 16px;
          }
          img {
            border-radius: 15px;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
  .team-swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    //background-image: url("../assets/left.png");
    -webkit-mask: url("../assets/left.png") no-repeat;
    mask: url("../assets/left.png") no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    background-color: #1195F0;
    left: 65px;
    top: 40%;
    transform: scale(0.75);
    position: absolute;
    z-index: 100;
    cursor: pointer;
  }

  .team-swiper-pre, .team-swiper-next {
    //background-image: url("../assets/left.png");
    -webkit-mask: url("../assets/left.png") no-repeat;
    mask: url("../assets/left.png") no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    background-color: #1195F0;
    left: 65px;
    top: 61%;
    position: absolute;
    z-index: 100;
    height: 28px;
    width: 17px;
    cursor: pointer;
  }

  .team-swiper-next {
    -webkit-mask: url("../assets/right.png") no-repeat;
    mask: url("../assets/right.png") no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    background-color: #1195F0;
    right: 82px;
    left: initial;
    top: 61%;
    cursor: pointer;
  }

  .swiper-button-disable {
    opacity: 0.35;
  }

  .swiper-button-next,
  .swiper-container-rtl .team-swiper-button-prev {
    -webkit-mask: url("../assets/right.png") no-repeat;
    mask: url("../assets/right.png") no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    background-color: #1195F0;
    right: 82px;
    top: 61%;
    transform: scale(0.75);
    cursor: pointer;
  }
  .swiper-pagination-team {
    display: none;
  }
}


