@media only screen and (max-width: 780px) {
  .scan {
    height: initial;

    .content {
      .title {
        padding-top: 40px;
      }

      .desc {
        margin-top: 10px;
        padding: 5px;
      }

      .con {
        .warp {
          display: flex;
          flex-direction: column;
          justify-content: center;
          row-gap: 10px;

          .item {
            margin: 0 auto;
            width: 300px;
            height: 280px;

            h2 {
              font-size: 20px;
            }
          }

          .btn {
            margin-top: 40px;
          }
        }
      }
    }
  }
}