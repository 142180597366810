
.product {
  background: #fff;
  height: 706px;
  width: 100%;
  position: relative;
  top: -162px;
  .title {
    margin-bottom: 28px;
    font-size: 40px;
  }
  .desc {
    font-size: 20px;
  }
  .content {
    width: 84%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 92px;
    .con {
      .warp {
        display: flex;
        justify-content: center;
        column-gap: 20px;

        .item {
          overflow: hidden;
          width: 390px;
          height: 370px;
          background: #FFFFFF;
          box-shadow: 7px 21px 18px 1px rgba(29,31,117,0.15);
          border-radius: 15px 15px 15px 15px;
          opacity: 1;
          border: 1px solid #D7D7D8;
          position: relative;
          cursor: pointer;

          .img-con {
            width: 130px;
            height: 130px;
            border-radius: 50%;
            background-color: #1295F0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            margin-top: 26px;
            background-clip: content-box;
            border: 10px solid rgba(18, 149, 240, 0.15);
          }

          img {
            width: 60%;
          }

          h2 {
            margin-top: 25px;
            margin-bottom: 7px;
            height: 25px;
            font-size: 20px;
            font-weight: bold;
            color: #1D1F75;
            width: 100%;
            text-align: center;
          }

          p {;
            height: 33px;
            font-size: 20px;
            font-weight: 400;
            color: #1D1F75;
          }

          .arrow {
            width: 40px;
            margin-top: 18px;
            cursor: pointer;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          }

          .info {
            display: inline-block;
            width: 83px;
            height: 0px;
            border: 3px solid #3eddcf;
            border-radius: 3px;
          }
        }
      }
    }
  }
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
  }
}