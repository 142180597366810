@media only screen and (min-width: 1280px) and (max-width: 1679px) {
  .join {
    &::after {
      height: 0px;
    }
    .title {
      padding-top: 89 * 0.76190476px;
    }
    .join-con {
      //width: 1280 * 0.76190476px;
      height: 661 * 0.76190476px;
    }

    .desc {
      font-size: 25 * 0.76190476px;
    }

    .link-icon {
      margin-bottom: 86 * 0.76190476px;
      a {
        width: 80 * 0.76190476px;
        height: 79 * 0.76190476px;
        img {
          width: 50 * 0.76190476px;
        }
      }
      a:not(:first-child) {
        margin-left: 31 * 0.76190476px;
      }
    }

    .btn-con {
      .btn {
        //width: 503 * 0.76190476px;
        height: 73 * 0.76190476px;
        box-shadow: 0 * 0.76190476px 3 * 0.76190476px 3 * 0.76190476px
          rgba(0, 6, 166, 0.12);
        border-radius: 36 * 0.76190476px;

        img {
          padding-left: 20 * 0.76190476px;
          width: 42 * 0.76190476px;
          height: 42 * 0.76190476px;
        }

        span {
          padding-left: 40 * 0.76190476px;
          font-size: 29 * 0.76190476px;
          line-height: 38 * 0.76190476px;
        }
      }
    }
  }

  [dir="rtl"] {
    .join {
      .link-icon {
        a:not(:first-child) {
          margin-right: 31 * 0.76190476px;
          margin-left: initial;
        }
      }

      .btn-con .btn img {
        padding-right: 20 * 0.76190476px;
      }
    }
  }
}
