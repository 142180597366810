

.warehouse {
  background: #EAEEFF;
  height: 880px;
  width: 100%;
  position: relative;
  .title {
    margin-bottom: 28px;
    font-size: 40px;
  }
  .desc {
    font-size: 20px;
  }
  .con {
    width: 88%;
    display: flex;
    justify-content: center;
    column-gap: 100px;
    margin: 0 auto;
    img {
      width: 870px;
    }
    .warehouse-desc {
      text-align: center;
      ul {
        margin-top: 8px;
      }
      li {
        width: 100%;
        padding-left: 20px;
        text-align: left;
        line-height: 33px;
        font-size: 18px;
        font-weight: 400;
        color: #1D1F75;
      }
      .btn {
        width: 161px;
        height: 50px;
        background: #1195F0;
        border-radius: 100px 100px 100px 100px;
        opacity: 1;
        text-align: center;
        color: #FFF;
        line-height: 50px;
        font-size: 18px;
        font-weight: 400;
        cursor: pointer;
        margin: 0 auto;
        margin-top: 28px;
        a {
          color: #FFF;
          text-decoration: none;
        }
      }
    }
    .pair {
      width: 264px;
      height: 123px;
      background: #FFFFFF;
      border-radius: 15px 15px 15px 15px;
      opacity: 1;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      justify-content: center;
      align-items: center;
      div:first-child {
        font-size: 60px;
        font-weight: bold;
        color: #1D1F75;
      }
      span {
        font-size: 16px;
        color: #1D1F75;
        font-weight: 400;
      }
    }
  }
}