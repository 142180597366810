@media only screen and (max-width: 1279px) {
  #root.cn {
    .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic4 {
      bottom: 309 * 0.55864198px;
    }
    .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic5 {
      bottom: 97 * 0.55864198px;
    }
    .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic4Text {
      top: -74 * 0.55864198px;
    }
    .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic1Text {
      left: -257 * 0.55864198px;
      top: -49 * 0.55864198px;
    }
    .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic5Text {
      left: 150 * 0.55864198px;
      top: -68 * 0.55864198px;
    }
  }
  .economicModelApplicationScenario .economicModelApplicationScenario-con .economicModelApplicationScenario-warp {
    flex-direction: column;
    align-items: center;
  }
  .economicModelApplicationScenario {
    .title {
      padding: 0;
      padding-bottom: 18px;
    }

    .desc {
      padding: 0;
      padding-bottom: 55px;
      margin: 0;
    }

    .economicModelApplicationScenario-con {
      width: 100%;
      padding: 46px 29px 78px;
      box-sizing: border-box;

      .economicModelApplicationScenario-warp {
        .economicModel-img {
          transform: scale(0.4);

          .economic3 {
            left: 380px;
          }
        }
        .applicationScenario-img {
          transform: scale(0.4);
          left: (-3em);
        }
        .economicModelApplicationScenario-swiper {
          width: 100%;

          .economicModelApplicationScenario-swiper-awesomeSwiper {
            width: 100%;
            height: 32em;
            box-shadow: 7 * 0.55864198px 21 * 0.55864198px 18 * 0.55864198px rgba(29, 31, 117, 0.15);
            border-radius: 15 * 0.55864198px;

            .economicModelApplicationScenario-swiper-title {
              font-size: 18px;
              padding-top: 45 * 0.55864198px;
              padding-bottom: 32 * 0.55864198px;
              padding-left: 65 * 0.55864198px;
            }

            .economicModelApplicationScenario-swiper-info {
              width: 83 * 0.55864198px;
              margin-left: 65 * 0.55864198px;
              margin-bottom: 38 * 0.55864198px;
            }

            .economicModelApplicationScenario-swiper-desc {
              width: 70%;
              height: 200px;
              font-size: 15px;
              padding-left: 65 * 0.55864198px;
              line-height: 36 * 0.55864198px;
            }

            .swiper-wrapper {
            }
          }
        }
      }
    }

    &.economicModel {
      height: auto;
    }

    &.applicationScenario {
      height: 1000px;

      .economicModelApplicationScenario-warp {
        .economicModelApplicationScenario-swiper {
          width: 100%;
          height: 519 * 0.55864198px;

          .economicModelApplicationScenario-swiper-desc {
            width: 100%;
            height: 218 * 0.55864198px;
          }
        }
      }
    }

    .swiper-pagination {
      bottom: 45 * 0.55864198px;
      left: 53 * 0.55864198px;
      .swiper-pagination-bullet {
        width: 16 * 0.55864198px;
        height: 16 * 0.55864198px;
        margin-left: 12 * 0.55864198px;

        &.swiper-pagination-bullet-active {
        }
      }
    }
  }
}
