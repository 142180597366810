@media only screen and (min-width: 1280px) and (max-width: 1800px) {
  #root.cn {
    .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic4 {
      bottom: 309 * 0.76190476px;
    }
    .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic5 {
      bottom: 97 * 0.76190476px;
    }
    .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic4Text {
      top: -74 * 0.76190476px;
    }
    .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic1Text {
      left: -257 * 0.76190476px;
      top: -49 * 0.76190476px;
    }
    .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic5Text {
      left: 150 * 0.76190476px;
      top: -68 * 0.76190476px;
    }
  }

  .economicModelApplicationScenario {
    .title {
      padding-top: 67 * 0.76190476px;
      padding-bottom: 23 * 0.76190476px;
    }

    .desc {
      margin-bottom: 118 * 0.76190476px;
    }

    .economicModelApplicationScenario-con {
      width: 1640 * 0.76190476px;

      .economicModelApplicationScenario-warp {
        .economicModel-img {
          width: 823 * 0.76190476px;
          height: 604 * 0.76190476px;
          .common {
            .img {
              width: 107 * 0.76190476px;
              height: 107 * 0.76190476px;
            }
          }
          .economic {
            &::after {
              width: 185 * 0.76190476px;
              height: 185 * 0.76190476px;
              border: 150 * 0.76190476px solid #dbeffd;
              margin-top: -35 * 0.76190476px;
            }
            &::before {
              width: 435 * 0.76190476px;
              height: 435 * 0.76190476px;
              left: 49 * 0.76190476px;
              top: 49 * 0.76190476px;
            }
            width: 533 * 0.76190476px;
            height: 533 * 0.76190476px;
          }
          .economic1 {
            bottom: 124 * 0.76190476px;
            left: 147 * 0.76190476px;
          }
          .economic2 {
            bottom: 344 * 0.76190476px;
            left: 170 * 0.76190476px;
          }
          .economic3 {
            bottom: 471 * 0.76190476px;
            left: 375 * 0.76190476px;
          }
          .economic4 {
            bottom: 286 * 0.76190476px;
            left: 600 * 0.76190476px;
          }
          .economic5 {
            width: 200 * 0.76190476px;
            bottom: 67 * 0.76190476px;
            left: 571 * 0.76190476px;
          }
          .text {
            width: 120 * 0.76190476px;
            font-size: 18px;
            line-height: 30 * 0.76190476px;
          }
          .economicText {
            bottom: -378 * 0.76190476px;
            font-size: 27 * 0.76190476px;
          }
          .economic1Text {
            left: -252 * 0.76190476px;
            top: -34 * 0.76190476px;
          }
          .economic2Text {
            left: -237 * 0.76190476px;
            top: -44 * 0.76190476px;
          }
          .economic3Text {
            left: -113 * 0.76190476px;
            top: -123 * 0.76190476px;
          }
          .economic4Text {
            left: 129 * 0.76190476px;
            top: -84 * 0.76190476px;
          }
          .economic5Text {
            left: 132 * 0.76190476px;
            top: -83 * 0.76190476px;
          }
        }
        .applicationScenario-img {
          width: 823 * 0.76190476px;
          height: 604 * 0.76190476px;
          .line1 {
            width: 100 * 0.76190476px;
            height: 5 * 0.76190476px;
            left: 374 * 0.76190476px;
            top: 297 * 0.76190476px;
          }
          .line2 {
            width: 100 * 0.76190476px;
            height: 5 * 0.76190476px;
            left: 454 * 0.76190476px;
            top: 168 * 0.76190476px;
          }
          .line3 {
            width: 100 * 0.76190476px;
            height: 5 * 0.76190476px;
            left: 537 * 0.76190476px;
            top: 297 * 0.76190476px;
          }
          .common {
            width: 150 * 0.76190476px;
            height: 150 * 0.76190476px;
            padding: 9 * 0.76190476px;
            font-size: 25 * 0.76190476px;
            .img {
              width: 74 * 0.76190476px;
              height: 74 * 0.76190476px;
            }
            .text {
              width: 120 * 0.76190476px;
              font-size: 18px;
              line-height: 30 * 0.76190476px;
            }
          }
          .application {
            width: 150 * 0.76190476px;
            height: 150 * 0.76190476px;
            margin-left: -161 * 0.76190476px;
            padding: 9 * 0.76190476px;
            font-size: 25 * 0.76190476px;
            border: 172 * 0.76190476px solid #dbeffd;
            &::after {
              width: 310 * 0.76190476px;
              height: 310 * 0.76190476px;
            }
            &::before {
              width: 318 * 0.76190476px;
              height: 318 * 0.76190476px;
            }
          }
          .application1 {
            top: 362 * 0.76190476px;
            left: 272 * 0.76190476px;
            .text {
              left: -117 * 0.76190476px;
            }
          }
          .application2 {
            top: 176 * 0.76190476px;
            left: 206 * 0.76190476px;
            .text {
              left: -106 * 0.76190476px;
            }
          }
          .application3 {
            top: 9 * 0.76190476px;
            left: 263 * 0.76190476px;
            .text {
              left: -120 * 0.76190476px;
            }
          }
          .application4 {
            top: -88 * 0.76190476px;
            left: 456 * 0.76190476px;

            .text {
              top: 10 * 0.76190476px;
              left: -13 * 0.76190476px;
            }
          }
          .application5 {
            top: 22 * 0.76190476px;
            left: 661 * 0.76190476px;
            .text {
              left: 57 * 0.76190476px;
            }
          }
          .application6 {
            top: 169 * 0.76190476px;
            left: 721 * 0.76190476px;
            .text {
              left: 95 * 0.76190476px;
            }
          }
          .application7 {
            top: 358 * 0.76190476px;
            left: 661 * 0.76190476px;
            .text {
              left: 95 * 0.76190476px;
            }
          }
          .application8 {
            top: 431 * 0.76190476px;
            left: 457 * 0.76190476px;
            .text {
              left: -12 * 0.76190476px;
              top: 122 * 0.76190476px;
            }
          }
          .application11 {
            top: 270 * 0.76190476px;
            left: 305 * 0.76190476px;
            .img {
              width: 91 * 0.76190476px;
              height: 89 * 0.76190476px;
            }
          }
          .application22 {
            top: 14 * 0.76190476px;
            left: 445 * 0.76190476px;
            .img {
              width: 91 * 0.76190476px;
              height: 89 * 0.76190476px;
            }
          }
          .application33 {
            top: 277 * 0.76190476px;
            left: 589 * 0.76190476px;
            .img {
              width: 91 * 0.76190476px;
              height: 89 * 0.76190476px;
            }
          }
        }
        .economicModelApplicationScenario-swiper {
          .economicModelApplicationScenario-swiper-awesomeSwiper {
            width: 674 * 0.76190476px;
            height: 512 * 0.76190476px;
            box-shadow: 7 * 0.76190476px 21 * 0.76190476px 18 * 0.76190476px
              rgba(29, 31, 117, 0.15);
            border-radius: 15 * 0.76190476px;

            .economicModelApplicationScenario-swiper-title {
              font-size: 35 * 0.76190476px;
              padding-top: 45 * 0.76190476px;
              padding-bottom: 32 * 0.76190476px;
              padding-left: 65 * 0.76190476px;
            }

            .economicModelApplicationScenario-swiper-info {
              width: 83 * 0.76190476px;
              margin-left: 65 * 0.76190476px;
              margin-bottom: 38 * 0.76190476px;
            }

            .economicModelApplicationScenario-swiper-desc {
              width: 572 * 0.76190476px;
              height: 258 * 0.76190476px;
              font-size: 26 * 0.76190476px;
              padding-left: 65 * 0.76190476px;
              line-height: 36 * 0.76190476px;
            }

            .swiper-wrapper {
            }
          }
        }
      }
    }

    &.economicModel {
      height: 1046 * 0.76190476px;
    }

    &.applicationScenario {
      height: 1060 * 0.76190476px;

      .economicModelApplicationScenario-warp {
        .economicModelApplicationScenario-swiper {
          width: 768 * 0.76190476px;
          height: 519 * 0.76190476px;

          .economicModelApplicationScenario-swiper-desc {
            width: 650 * 0.76190476px;
            height: 218 * 0.76190476px;
          }
        }
      }
    }

    .swiper-pagination {
      bottom: -45 * 0.46190476px;
      left: -53 * 0.39190476px;
      .swiper-pagination-bullet {
        width: 16 * 0.76190476px;
        height: 16 * 0.76190476px;
        margin-left: 12 * 0.76190476px;

        &.swiper-pagination-bullet-active {
        }
      }
    }
  }

  [dir="rtl"] {
    #root.cn {
      .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic4 {
        bottom: 359 * 0.76190476px;
      }
      .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic1Text {
        right: -257 * 0.76190476px;
      }
      .economicModelApplicationScenario
      .economicModelApplicationScenario-con
      .economicModelApplicationScenario-warp
      .economicModel-img
      .economic5Text {
        right: 150 * 0.76190476px;
      }
    }

    .economicModelApplicationScenario {
      .economicModelApplicationScenario-con {
        .economicModelApplicationScenario-warp {
          .economicModel-img {
            .economic {
              &::before {
                right: 49 * 0.76190476px;
              }
            }
            .economic1 {
              right: -147 * 0.76190476px;
            }
            .economic2 {
              right: -80 * 0.76190476px;
            }
            .economic3 {
              right: 375 * 0.76190476px;
            }
            .economic4 {
              right: 600 * 0.76190476px;
            }
            .economic5 {
              right: 571 * 0.76190476px;
            }
            .economic1Text {
              right: -252 * 0.76190476px;
            }
            .economic2Text {
              right: -237 * 0.76190476px;
            }
            .economic3Text {
              right: -113 * 0.76190476px;
            }
            .economic4Text {
              right: 129 * 0.76190476px;
            }
            .economic5Text {
              right: 132 * 0.76190476px;
            }
          }
          .applicationScenario-img {
            .line1 {
              right: 374 * 0.76190476px;
            }
            .line2 {
              right: 454 * 0.76190476px;
            }
            .line3 {
              right: 537 * 0.76190476px;
            }
            .application {
              margin-right: -161 * 0.76190476px;
            }
            .application1 {
              right: 272 * 0.76190476px;
              .text {
                right: -117 * 0.76190476px;
              }
            }
            .application2 {
              right: 206 * 0.76190476px;
              .text {
                right: -106 * 0.76190476px;
              }
            }
            .application3 {
              right: 263 * 0.76190476px;
              .text {
                right: -120 * 0.76190476px;
              }
            }
            .application4 {
              right: 456 * 0.76190476px;

              .text {
                right: -13 * 0.76190476px;
              }
            }
            .application5 {
              right: 661 * 0.76190476px;
              .text {
                right: 57 * 0.76190476px;
              }
            }
            .application6 {
              right: 721 * 0.76190476px;
              .text {
                right: 95 * 0.76190476px;
              }
            }
            .application7 {
              right: 661 * 0.76190476px;
              .text {
                right: 95 * 0.76190476px;
              }
            }
            .application8 {
              right: 457 * 0.76190476px;
              .text {
                right: -12 * 0.76190476px;
              }
            }
            .application11 {
              right: 305 * 0.76190476px;
            }
            .application22 {
              right: 445 * 0.76190476px;
            }
            .application33 {
              right: 589 * 0.76190476px;
            }
          }
          .economicModelApplicationScenario-swiper {
            .economicModelApplicationScenario-swiper-awesomeSwiper {
              .economicModelApplicationScenario-swiper-title {
                padding-right: 65 * 0.76190476px;
              }

              .economicModelApplicationScenario-swiper-info {
                margin-right: 65 * 0.76190476px;
              }

              .economicModelApplicationScenario-swiper-desc {
                padding-right: 65 * 0.76190476px;
              }
            }
          }
        }
      }

      .swiper-pagination {
        text-align: right;
        right: 53 * 0.39190476px;
        .swiper-pagination-bullet {
          margin-right: 12 * 0.76190476px;
        }
      }
    }
  }

  .ru, .ua, .fr, .es {
    .applicationSwiper {
      height: 500px !important;
      .economicModelApplicationScenario-swiper-awesomeSwiper {
        height: 485px !important;
      }
    }
  }

  .tk {
    .economicModelApplicationScenario-warp {
      .application4 {
        .text {
          top: -11px !important;
        }
      }
    }
  }

  .ru, .ua {
    .economicModelApplicationScenario-warp {
      .economic2Text {
        left: -208px !important;
      }
      .application1 {
        .text {
          left: -115px !important;
        }
      }

      .application2 {
        .text {
          left: -120px !important;
        }
      }
      .application3 {
        .text {
          left: -115px !important;
        }
      }
      .application5 {
        .text {
          left: 64px !important;
        }
      }

      .application7 {
        .text {
          left: 20px !important;
          top: 93px;
        }
      }
    }
  }
}
