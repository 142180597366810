
.partners {
  background: #1195F0;
  height: 506px;
  width: 100%;
  position: relative;
  .content {
    .title, .desc {
      color: #FFF;
    }
    .title {
      margin-bottom: 15px;
      font-size: 40px;
    }
    .desc {
      font-size: 20px;
      font-family: Segoe UI-Regular, Segoe UI;
    }
  }
  .con {
    width: 82%;
    margin: 0 auto;
    .wrap {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 10px;
      row-gap: 15px;
      justify-items: center;
      .item {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px;
        width: 220px;
        height: 63px;
        background: #FFFFFF;
        border-radius: 13px 13px 13px 13px;
        opacity: 1;
        span {
          font-size: 20px;
          font-weight: 400;
          color: #1D1F75;
        }
      }
    }
  }
}