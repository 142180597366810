@media only screen and (max-width: 780px) {
  .medium {
    height: initial;

    .content {
      .desc {
        font-size: 15px;
      }

      .team-swiper-pre, .team-swiper-next {
        display: none;
      }
    }

    .con {
      .swiper-wrapper {
        height: 300px;

        .warp {
          margin-top: 20px;

          .item {
            width: 300px;
            box-sizing: border-box;
            margin: 0 10px;
          }
        }
      }

      .swiper-pagination-team {
        display: block;
        position: absolute;
        transform: translate(-50%) scale(0.75);
        bottom: 75px;
        left: 50%;
        z-index: 1;

        .swiper-pagination-bullet {
          width: 12px;
          height: 12px;
          opacity: 0.4;
          margin-left: 12px;
          background: #000;

          &.swiper-pagination-bullet-active {
            opacity: 1;
            background: #007aff;
          }
        }
      }
    }
  }
}