.team {
  background: #FFF;
  width: 100%;
  .head {
    margin-top: 130px;
    background: #EAEEFF;
  }
  .title {
    color: #1D1F75;
    padding-top: 89px;
    padding-bottom: 21px;
  }
  .desc {
    color: #1D1F75;
    padding-bottom: 104px;
    margin-bottom: 40px;
  }
  .team-con {
    width: 85%;
    margin: auto;
    position: relative;
    text-align: left;

    .swiper-wrapper {
      padding-top: 10px;
      .warp {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 15px;
      }

      .item {
        width: 456px;
        height: 365px;
        background: #FFFFFF;
        box-shadow: 7px 21px 18px 1px rgba(29,31,117,0.15);
        border-radius: 15px 15px 15px 15px;
        opacity: 1;

        .top {
          position: relative;
          margin-top: -61.7px;
          padding-left: 31px;
          padding-right: 32px;
          align-items: flex-end;
          display: flex;
          justify-content: space-between;

          .left {
            display: flex;
            align-items: flex-end;

            .headImg {
              margin-right: 20px;
              width: 123px;
              height: 123px;
              background: #fff;
              border-radius: 50px;
            }

            .position {
              display: flex;
              flex-direction: column;

              .info {
                position: relative;
                top: -60px;
                width: 132px;
                height: 0px;
                border-radius: 2px;
                border: 4px solid #3eddcf;
              }

              .text {
                position: relative;
                top: -20px;
                color: #363872;
                font-size: 26px;
              }
            }
          }

          .right {
            img {
              width: 43px;
              height: 43px;
            }

            .telegram {
              margin-left: 15px;
            }
          }
        }

        .team-name {
          margin-top: 20px;
          padding-top: 28px;
          padding-left: 52px;
          font-size: 25px;
          font-weight: bold;
          color: #1d1f75;
        }
        .team-position {
          padding-left: 52px;
          padding-top: 21px;
          padding-bottom: 36px;
          color: #1d1f75;
          font-size: 20px;
        }
        .team-desc {
          padding-left: 52px;
          padding-right: 30px;
          font-size: 20px;
          line-height: 28px;
          color: #363872;
        }
      }
    }
  }

  .team-swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url("../assets/left.png");
    left: -70px;
    top: 60%;
    transform: scale(0.75);
    position: absolute;
    z-index: 100;
  }

  .team-swiper-pre, .team-swiper-next {
    background-image: url("../assets/left.png");
    left: -70px;
    top: 60%;
    position: absolute;
    z-index: 100;
    height: 28px;
    width: 17px;
  }

  .team-swiper-next {
    background-image: url("../assets/right.png");
    right: -70px;
    left: initial;
    top: 60%;
  }

  .swiper-button-disable {
    opacity: 0.35;
  }

  .swiper-button-next,
  .swiper-container-rtl .team-swiper-button-prev {
    background-image: url("../assets/right.png");
    right: -70px;
    top: 60%;
    transform: scale(0.75);
  }
  .swiper-pagination-team {
    position: absolute;
    transform: translate(-50%) scale(0.75);
    bottom: 75px;
    left: 50%;
    z-index: 1;
    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      opacity: 0.4;
      margin-left: 12px;
      background: #fff;
      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}
.cn {
  .team-name{
    margin-top: 50px;
  }
}


[dir="rtl"] {
    .team-con {
      text-align: right;
      .swiper-wrapper {
        height: 987px;
        .warp {
          display: flex;
        }
        .item {
          &:not(:first-child) {
            margin-right: 16px;
          }

          .top {
            padding-right: 31px;
            padding-left: 32px;
            align-items: flex-end;
            .left {
              display: flex;
              align-items: flex-end;
            }
            .right {
              .telegram {
                margin-right: 15px;
              }
            }
          }
          .team-name {
            padding-right: 52px;
          }
          .team-position {
            padding-right: 52px;
          }
          .team-desc {
            padding-right: 52px;
          }
        }
      }
    }
}
