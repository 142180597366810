@media only screen and (min-width: 1280px) and (max-width: 1679px) {
  .foot {
    .foot-con {
      width: 1640 * 0.76190476px;
      margin: 0 auto 70 * 0.76190476px;
      font-size: 20px;

      .foot-title {
        font-size: 25px;
        padding-top: 30 * 0.76190476px;
        margin-bottom: 45 * 0.76190476px;
      }

      .about {
        width: 505 * 0.76190476px;
        .about-desc {
          width: 405 * 0.76190476px;
          line-height: 33 * 0.76190476px;
        }

        .about-email {
          margin-top: 35 * 0.76190476px;

          img {
            width: 31.5 * 0.76190476px;
            height: 21 * 0.76190476px;
            margin-right: 5 * 0.76190476px;
          }
          a {
          }
        }
      }
      .emailTip {
        margin-top: 15* 0.76190476px;
        font-size: 20* 0.76190476px;
        line-height: 30* 0.76190476px;
      }
      .link {
        width: 330 * 0.76190476px;

        .link-item {
          margin-top: 20 * 0.76190476px;
        }
      }

      .resources {
        width: 300 * 0.76190476px;
      }

      .subscribe {
        .subscribe-desc {
          width: 472 * 0.76190476px;
          line-height: 33 * 0.76190476px;
        }

        .send {
          margin-top: 35 * 0.76190476px;
          width: 508 * 0.76190476px;
          height: 73 * 0.76190476px;
          box-shadow: 0 * 0.76190476px 3 * 0.76190476px 3 * 0.76190476px
            rgba(0, 6, 166, 0.12);
          border-radius: 36 * 0.76190476px;

          input {
            border-radius: 36 * 0.76190476px;
            font-size: 20px;
            height: 73 * 0.76190476px;
            line-height: 73 * 0.76190476px;
            padding-left: 30 * 0.76190476px;
          }

          span {
            right: -5 * 0.76190476px;
            width: 140 * 0.76190476px;
            height: 78 * 0.76190476px;
            font-size: 20px;
            line-height: 78 * 0.76190476px;
            border-radius: 100 * 0.76190476px;
            //box-shadow: 0 * 0.76190476px 3 * 0.76190476px #1195f0;
          }
        }
      }
    }

    .copyright {
      height: 60 * 0.76190476px;
      line-height: 60 * 0.76190476px;
      font-size: 18 * 0.76190476px;
    }

    .top {
      bottom: 40 * 0.76190476px;
    }
    .alert {
      .alert-content {
        padding: 64 * 0.76190476px 122 * 0.76190476px;
        width: 714 * 0.76190476px;
        height: 162 * 0.76190476px;
        box-shadow: 0px 10 * 0.76190476px 18 * 0.76190476px
          rgba(29, 31, 117, 0.1);
        border-radius: 15 * 0.76190476px;
        .alert-close {
          top: 5 * 0.76190476px;
          right: 15 * 0.76190476px;
          font-size: 30 * 0.76190476px;
        }
        .alert-title {
          font-size: 40 * 0.76190476px;
          margin-bottom: 40 * 0.76190476px;
        }
        .alert-desc {
          font-size: 25 * 0.76190476px;
          line-height: 30 * 0.76190476px;
        }
      }
    }
  }
}
