.title {
  color: #1d1f75;
  font-size: 40px;
  text-align: center;
  padding-top: 66px;
  font-weight: bold;
}

.desc {
  margin: 0 auto;
  width: 942px;
  color: #363872;
  font-size: 20px;
  line-height: 33px;
  text-align: center;
  margin-bottom: 81px;
}
@media only screen and (max-width: 1279px) {
  .title {
    width: auto;
    font-size: 24px;
  }
  
  .desc {
    line-height: 20px;
    margin:0;
    width: auto;
    font-size: 15px;
  }
}
